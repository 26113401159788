<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>Survey Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" small text to="/survey">
            <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
          </v-btn>
        </v-toolbar>
        <v-tabs right>
          <v-tab :to="`/survey/details/${id}`">Survey Details</v-tab>
          <v-tab :to="`/survey/details/${id}/rules`">Rules</v-tab>
          <v-tab :to="`/survey/details/${id}/schedules`">Schedules</v-tab>
        </v-tabs>
        <v-card-text>
          <div>
            <router-view />
          </div>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  name: "surveyDetails",
  data: () => ({
    search: "",
    id: null
  }),
  watch: {
    "$route.params.id": {
      handler: "setId",
      immediate: true
    }
  },
  methods: {
    setId(id) {
      this.id = id;
    }
  },
  created() {}
};
</script>